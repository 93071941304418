<template>
    <div>
        <banner banner-name="归档" :background-img="$store.getters.getBackground('archives')"></banner>
        <v-card class="blog-container an-blog-InUp">
            <ul class="timeline">
                <li v-cloak class="timeline-item timeline-title  animate__animated animate__fadeInUp">
                    <div class="timeline-circle "></div>
                    目前共计{{total}}篇文章，继续加油
                </li>
                <router-link tag="li" :to="'/articles/'+archive.id" :key="archive.id" v-for="archive in archivesData"
                    class="timeline-item animate__animated animate__fadeInUp">
                    <div class="timeline-circle"></div>
                    <span class="pr-4">{{archive.create_time}}</span>{{archive.articles_title}}
                </router-link>
            </ul>

            <v-pagination
                    v-model="page"
                    :length="totalPages"
                    class="mt-10"
                    @input="handleCurrentChange"
            ></v-pagination>
        </v-card>
    </div>
</template>

<script>
  import Banner from "@/components/banner/Banner";
  import {getArticlesArchivesApi} from "@/network/interface";

  export default {
    name: "Archives",
    data() {
      return {
        currentPage: 1, // 当前页数
        page: 1, // 改变的页数
        totalPages: 1, // 共多少页
        archivesData: [], // 数据
        total: ""
      }
    },
    created() {
      this.getArticlesArchives({pageNum: this.pageNum})
    },
    methods: {
      handleCurrentChange(number) {
        if (this.currentPage !== this.page) {
          this.currentPage = number
          this.getArticlesArchives({pageNum: number})
        }
      },
      getArticlesArchives(params) {
        getArticlesArchivesApi(params).then(resp => {
          this.totalPages = resp.data.totalPages
          this.total = resp.data.total
          this.archivesData = resp.data.result


        })
      }
    },
    components: {
      Banner
    }
  }
</script>

<style scoped>

    [v-cloak] {
        display: none;
    }

    .timeline {
        padding-left: 0;
        border-radius: 0 !important;
        border-left: 3px solid #dbdde0;
    }

    .timeline-item {
        position: relative;
        margin-top: 24px;
        padding-bottom: 24px;
        margin-left: 28px;
        border-bottom: 1px dotted #dbdde0;
        color: #898989;


    }

    .timeline-title {
        font-size: 1.2rem;
        margin-top: 0 !important;
        border-bottom: 0 !important;
        color: #565f66;
        pointer-events: none !important; /*hover阻断*/
    }

    .timeline-item span {
        color: #626262 !important;
        font-size: 0.7rem;
    }


    .timeline-title .timeline-circle {
        width: 20px !important;
        height: 20px !important;
        transform: translate(-50%, 20%) !important;
        border-radius: 10px !important;

    }

    .timeline-circle {
        position: absolute;
        left: -30px;
        transform: translate(-50%, 45%) !important;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: #dbdde0;
        transition: all 1s;
    }

    .theme--dark .timeline-item,
    .theme--dark .timeline-item span {
        color: #eeeeee !important;
    }

    .timeline-item:hover,
    .timeline-item:hover span {
        color: #6185c9 !important;
    }

    .timeline-item:hover .timeline-circle {
        background-color: #6185c9;
    }


    @media screen and (max-width: 750px) {
        .timeline {
            margin: 20px 10px;
        }

        .timeline-item {
            margin-left: 20px;
        }

        .timeline-circle {
            left: -20px;
        }

    }

</style>
