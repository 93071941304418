<template>
  <!--banner-->
  <div>
    <slot :backgroundImg="backgroundImg" :bannerName="bannerName">
      <div :style="{backgroundImage:'url('+backgroundImg+')' }" class="banner an-blog-InDown">
        <div class="banner-title">{{bannerName}}</div>
      </div>
    </slot>
  </div>
</template>

<script>
  export default {
    name: "Banner",
    props: {
      bannerName: {
        name: String,
        required: true
      },
      backgroundImg: {
        name: String,
        default: function () {
          return "https://zwb-typora.oss-cn-hangzhou.aliyuncs.com/images/53fd1a9a6db84b586659afc4e65035f7--1305060157.jpg"
        }
      }
    }
  }
</script>

<style scoped>

  .banner {
    top: 0;
    left: 0;
    right: 0;
    height: 450px;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 15%;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .banner-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #eeeeee;
    padding-top: 70px;

  }

  @media screen and (max-width: 750px) {
    .banner {
      height: 280px;
    }

    .banner-title {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 1900px) {
    .banner {
      height: 500px;
    }
  }
</style>
